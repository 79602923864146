import ContentCard from '@/components/app/cards/ContentCard';
import { ProgramOrGuideItem } from '@/components/app/cards/PacksList/types';
import PackClassLink from '@/components/app/ui/PackClassLink';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';

import { Subtitle, Title, TitleContainer, Wrapper } from './styles';
import { CardGridProps } from './types';

const CardGrid = ({ pack, searchQuery }: CardGridProps) => {
	const packItems = pack.items as ProgramOrGuideItem[];

	// Load feature flag
	const { flagValues, flagLoading } = useFeatureFlags(
		'b2b-moments-that-matter',
		'support-pack-of-packs-on-web',
	);
	const hasMomentsThatMatter = !flagLoading && flagValues['b2b-moments-that-matter'];
	const hasSupportPackOfPacks = !flagLoading && flagValues['support-pack-of-packs-on-web'];

	const supportedPacks = packItems.filter(packItem => {
		return (
			packItem.type === 'program' ||
			packItem.type === 'guide' ||
			(hasSupportPackOfPacks && packItem.type === 'pack')
		);
	});

	return (
		<>
			{hasMomentsThatMatter ? (
				<TitleContainer>
					{pack.subtitle && <Subtitle>{pack.subtitle}</Subtitle>}
					{pack.title && !pack.subtitle && <Title>{pack.title}</Title>}
					{pack.has_more && <PackClassLink pack={pack} />}
				</TitleContainer>
			) : (
				<TitleContainer>
					{pack.title && <Title>{pack.title}</Title>}
					{pack.has_more && <PackClassLink pack={pack} />}
				</TitleContainer>
			)}
			<Wrapper>
				{supportedPacks.map((packItem, index) => (
					<ContentCard
						pack={pack}
						key={packItem.guide_id ?? packItem.program_id}
						card={packItem}
						searchQuery={{
							...searchQuery,
							pack_item_index: index,
						}}
					/>
				))}
			</Wrapper>
		</>
	);
};

export default CardGrid;
